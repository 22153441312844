import "./alterbox.css"

export default function BaseModal({
                                      className,
                                      innerClassName,
                                      isBoxOpen, setIsBoxOpen,
                                      autoClose = true,
                                      defaultHeader = true,
                                      title,
                                      ...props
                                  }) {


    function close() {
        setIsBoxOpen(false)
    }

    return (

        <div
            onClick={() => {
                if (setIsBoxOpen && autoClose)
                    setIsBoxOpen(false)
            }}
            className={`${isBoxOpen ? " " : "hidden"} fixed  inset-0 z-[900] flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center appear-done enter-done ${className}`}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`${innerClassName} ${isBoxOpen ? " bottom-to-top-animation " : ""} w-full px-6 py-4 overflow-auto bg-white rounded-t-lg  max-h-[90%] sm:rounded-lg sm:m-4 sm:max-w-xl md:max-w-[80%] appear-done enter-done`}>
                {defaultHeader && <header className="flex justify-between border-b border-b-gray-300">
                    <p className=" mb-2 text-lg font-semibold text-gray-700 dark:text-white ">{title}</p>
                    <button
                        onClick={close}
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded  hover: hover:text-gray-700"
                        aria-label="close">
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
                            <path
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipPath="evenodd" fillRule="evenodd"></path>
                        </svg>
                    </button>
                </header>}
                {
                    props.children
                }
            </div>
        </div>

    )
}
