import DarkModeButton from "../components/Buttons/FloatingDarkButton/FloatingDarkMode";
import {Navigate, Route, Routes} from "react-router-dom";
import AuthEntry from "./auth/authEntry";
import DemoScreenEntry from "../DemoScreens/Entry";
import {useSession} from "../redux/Reducers/AuthReducer";
import {NotificationWrapper} from "../redux/Reducers/notification-reducer/NotificationReducer";

export default function Root({className, ...props}) {
    const {authActions,SESSION_STORAGE_KEY} = useSession();
    if (!authActions.isUserAuthenticated() && localStorage.getItem(SESSION_STORAGE_KEY) === null) {
        return (
            <div className={`w-[100vw] h-[100vh] ${className}`}>
                <Routes>
                    <Route path="/auth/*" element={<AuthEntry/>}/>
                    <Route path="*" element={<Navigate to="/auth/login" replace/>}/>
                </Routes>
                <DarkModeButton/>
            </div>

        )
    }


    return (
        <div className={`w-[100vw] h-[100vh] ${className}`}>
            <NotificationWrapper>
            <Routes>
                <Route path="*" element={<DemoScreenEntry/>}/>
            </Routes>
            <DarkModeButton/>
            </NotificationWrapper>
        </div>

    )
}
