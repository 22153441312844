import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AiFillEye, AiOutlineAppstoreAdd} from "react-icons/ai";

import {MdDelete, MdEditNote} from "react-icons/md";

import moment from "moment";
import {BiBookAdd, BiCross, BiPhone, BiX} from "react-icons/bi";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import TableContainer from "../../../components/Table/TableContainer";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import Avatar from "../../../components/Image/Avatar/Avatar";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import {getUri, isNoteAvailable} from "../../../utils/utils";
import Table from "../../../components/Table/Table";
import SearchInput from "../../../components/fields/search-input/search-input";
import PaymentVerificationModal from "./PaymentVerificationModal";
import {BsCash} from "react-icons/bs";
import {toast} from "react-toastify";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {is_success} from "../../../API/Axios/Status";
import {CgNotes} from "react-icons/cg";

export default function AppointmentTable({className, isModal, onRowSelect, ...props}) {

    const [viewNotes, setViewNotes] = useState(false)
    let [data, filter, isLoading, patientActions] = useListApi(URLS.appointmentList, {
        search: "",
    });

    let [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    let [response, setResponse] = useState();
    function InitiateCall(appointment){
//     check the date and time of the appointment
        function genCallRequest(appointment){
            let data = {
                appointment: appointment.id
            }
            axiosInstance.post(URLS.initiateCallRequest, data).then((res) => {
                let data = res?.data;
                if (is_success(res?.status)) {
                    toast.success("Call initiated successfully")
                    // redirect to call page
                    window.location.href = `/app/patient-call/${appointment.id}`

                }
                else{
                    toast.error(data?.message)
                }
            }).catch((err) => {
                            console.log("IN HERE")
                            console.log(err)


                toast.error(err?.response?.data?.message || "An error occurred while initiating call")
            })


        }
        genCallRequest(appointment)



    }
    const navigation = useNavigate()
    function onNoteClick(appointment) {
        navigation(`/app/patient/notes`, {
            state: appointment
        })
    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PaymentVerificationModal
                isOpen={isPaymentModalOpen}
                response={response}
                title={"Payment Verification"}
                setIsOpen={setIsPaymentModalOpen}
            />

            {!isModal && <div className={"w-full flex mb-10 justify-end items-center"}>
                <Link title={"Create Appointment"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"/app/appointments/add"}>
                    <BiBookAdd className={"text-base"}/>
                </Link>
            </div>
            }
            {
                <div
                    className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
                >
                    <SearchInput
                        hideRightIcon={true}
                        onChange={(e) => {
                            patientActions.updateFilter({
                                search: e.target.value
                            })
                        }}
                        outerClassName={"border-[1px]  rounded-full"}
                        placeholder={"Search Appointment"}
                    />


                </div>
            }

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Doctor</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell>Date </TableCell>
                            <TableCell>Time </TableCell>
                            <TableCell >Call Type</TableCell>
                            <TableCell >Event</TableCell>
                            <TableCell>Notes </TableCell>
                            <TableCell>Status </TableCell>
                            <TableCell>Action </TableCell>

                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((row, i) => {
                            let patient = row?.patient;
                            let doctor = row?.doctor;
                            return <TableRow
                                onClick={() => {
                                    if (onRowSelect)
                                        onRowSelect(row)
                                }}
                                className={"cursor-pointer"}
                                key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(doctor?.user?.dp)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{doctor?.user?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(patient?.user?.dp)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{patient?.user?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                            moment(row?.date).format("DD-MM-YYYY")

                                    }</span>
                                </TableCell>


                                <TableCell>
                                    <span className="text-sm">{
                                        row?.startTime + " - " + row?.endTime
                                    }</span>
                                </TableCell>

                                <TableCell>
                                    <span className="text-sm">{row?.media}</span>

                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{row?.call_type}</span>

                                </TableCell>
                                <TableCell>
                                    {isNoteAvailable(row) &&
                                        <div onClick={() => {
                                        onNoteClick(row)
                                    }}
                                          className={"w-10 items-center flex justify-center rounded-full h-10 bg-brand-100 hover:bg-brand-300 "}>
                                        <CgNotes className={"text-brand-700"}/>

                                    </div>}
                                    {/*<div className={`${viewNotes === row.id ? "absolute" : "hidden"} bg-white`}>*/}
                                    {/*            <span*/}
                                    {/*                className={"absolute -top-2 -right-2 text-xs overflow-auto w-80 h-80 border shadow-lg bg-white z-[900] dark:border-gray-800 rounded dark:shadow-gray-600 dark:bg-darkTheme-700  p-1"}>*/}
                                    {/*                <div className={"flex flex-row-reverse"}>*/}
                                    {/*                    <BiX className={"text-3xl text-red-600"} onClick={() => {*/}
                                    {/*                        setViewNotes(false)*/}
                                    {/*                    }} />*/}
                                    {/*                </div>*/}
                                    {/*                <div className={"flex flex-wrap flex-col gap-2"}>*/}
                                    {/*                    <p className={"flex flex-wrap text-xl"}>{row?.symptoms}</p>*/}
                                    {/*                    <p className={"flex flex-wrap "}>{row?.remarks}</p>*/}
                                    {/*                </div>*/}
                                    {/*            </span>*/}

                                    {/*</div>*/}


                                </TableCell>
                                <TableCell
                                    className={""}

                                >
                                    <span className={`text-sm p-1 rounded-lg
                                    ${row?.status === "PAID" && "text-green-500 border border-green-500"}
                                    ${row?.status === "UNPAID" && "text-red-500 border border-red-500"}
                                    `}>{row?.status}</span>

                                </TableCell>
                                <TableCell>
                                    <span
                                        onClick={(e)=>{
                                            if(row.status ==="UNPAID") {
                                                setResponse({
                                                    data: row,
                                                    payment: {
                                                        data: row?.payment,
                                                    }
                                                })
                                                setIsPaymentModalOpen(true)
                                            }

                                            if(row.status ==="PAID" ) {
                                                    InitiateCall(row)
                                            }
                                            if(row.status ==="In Call" ) {
                                            //     link to call page
                                            //     window.location.href = `/app/patient-call/${row.id}`
                                                InitiateCall(row)


                                            }

                                        }}
                                    >
                                        {row.status === "UNPAID" && <div className={"flex justify-center text-center   gap-x-2  items-center bg-green-500 hover:bg-green-600 text-white rounded p-2 "}>
                                            <BsCash className={"text-lg"}/>
                                            <span className={"text-sm"}>Pay</span>

                                        </div>
                                        }
                                        {
                                            row.status === "PAID" && <div className={"flex justify-center text-center   gap-x-2  items-center bg-blue-500 hover:bg-blue-600 text-white rounded p-2 "}>
                                                <BiPhone className={"text-lg"}/>
                                                <span className={"text-sm"}>Initate Call</span>

                                            </div>
                                        }
                                        {
                                            row.status === "In Call" && <div className={"flex justify-center text-center   gap-x-2  items-center bg-yellow-500 hover:bg-yellow-600 text-white rounded p-2 "}>
                                                <BiPhone className={"text-lg"}/>
                                                <span className={"text-sm"}>Go To Call</span>

                                            </div>
                                        }


                                    </span>

                                </TableCell>

                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            patientActions.loadNextPage()
                        }}
                        onPrev={(e) => patientActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            patientActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
