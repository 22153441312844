import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {useEffect, useState} from "react";
import UploadFile from "../../../API/UploadFile";
import DpCircle from "../../../components/avatar/dp/DpCircle";
import {getUri, truchateString} from "../../../utils/utils";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";
import {Select} from "antd";

export default function AddUpdateDevice({className, ...props}) {
    let {id} = useParams();
    const [form, error, loading, setForm, actions] = useForm({
        deviceId: "",
        imei: "",
        modelNo: "",
        deviceType: "bp",
        status:"working",
        vendor: "",
        firmwareVersion: "",
        hardwareVersion: "",

    });
    const navigation = useNavigate();
    const [loadingData, setLoadingData] = useState(false);

    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.DeviceCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setForm(
                data
            )
        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
            navigation("/app/devices")
        }).finally(() => {
            setLoadingData(false);
        })

    }

    useEffect(() => {
        if (id) {
            getFormData()
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        if (id) {
            actions.put(URLS.DeviceCrud).then(res => {
                toast.success("Device Updated Successfully")
                navigation("/app/devices")
            }).catch(err => {
                console.log(err);
            })
            return;
        }


        actions.post(URLS.DeviceCrud,).then(res => {
            toast.success("Device Added Successfully")
            navigation("/app/devices")
        }).catch(err => {
            console.log(err);
        })

    }

    if (loadingData)
        return <PageLoading/>

    return (
        <form onSubmit={handleSubmit}
              className={`w-full flex flex-col h-full overflow-auto  pb-20 mt-2 xl:px-10 ${className}`}>
            <PageTitle>
                {
                    id ? "Update Device" : "Add Device"
                }
            </PageTitle>


            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    Information
                </PageTitle>

                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4"}>
                    <FormItem
                        label={"Device ID"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.deviceId}
                    >
                        <input
                            value={form?.deviceId}
                            placeholder={"12345678"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, deviceId: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"IMEI"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.imei}
                    >
                        <input
                            value={form?.imei}
                            placeholder={"12345678"}
                            min={0}
                            required={true}

                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, imei: e.target.value})
                            }}
                        />
                    </FormItem>
                    <div className={"grid grid-cols-1 gap-2"}>

                        <FormItem
                            label={"Model No."}
                            type={"error"}
                            variant={"auth"}
                            message={error?.modelNo}
                        >
                            <input
                                value={form?.modelNo}
                                required={true}
                                type={"text"}

                                onChange={(e) => {


                                    setForm({...form, modelNo: e.target.value})
                                }}
                            />
                        </FormItem>
                    </div>
                    <FormItem
                        label={"Device Type"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.deviceType}
                    >
                        <Select
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            placeholder={"Select Device Type"}
                            options={[
                                {
                                    label: "Blood Pressure",
                                    value: "bp"
                                },
                                {
                                    label: "Weight",
                                    value: "weight"
                                },
                                {
                                    label: "Sp02",
                                    value: "sp02"
                                },
                                {
                                    label: "Sugar",
                                    value: "sugar"
                                },
                            ]}
                            value={form?.deviceType}
                            required={true}
                            onChange={(value) => {
                                setForm({
                                    ...form, deviceType: value
                                })
                            }}
                        >

                        </Select>
                    </FormItem>
                    <FormItem
                        label={"Status"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.status}
                    >
                        <Select

                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            placeholder={"Select Status"}
                            options={[
                                {
                                    label: "Working Condition",
                                    value: "working"
                                },
                                {
                                    label: "Broken",
                                    value: "broken"
                                },
                            ]}
                            value={form?.status}
                            required={true}
                            onChange={(value) => {
                                setForm({
                                    ...form, status: value
                                })
                            }}
                        >

                        </Select>
                    </FormItem>

                    <FormItem
                        label={"Vendor"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.vendor}
                    >
                        <Select
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            placeholder={"Select Vendor"}
                            options={[
                                {
                                    label: "ABC",
                                    value: "abc"
                                },
                            ]}
                            value={form?.vendor}
                            required={false}
                            onChange={(value) => {
                                setForm({
                                    ...form, vendor: value
                                })
                            }}
                        >
                        </Select>
                    </FormItem>


                    <FormItem
                        label={"Firmware Version"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.firmwareVersion}
                    >
                        <input
                            value={form?.firmwareVersion}
                            placeholder={"12345678"}
                            min={0}
                            required={false}

                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, firmwareVersion: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Hardware Version"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.hardwareVersion}
                    >
                        <input
                            value={form?.hardwareVersion}
                            placeholder={"12345678"}
                            min={0}
                            required={false}

                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, hardwareVersion: e.target.value})
                            }}
                        />
                    </FormItem>

                </div>


                <FormButton
                    loading={loading}
                    type={"submit"} className={" mt-8 w-[500px] m-auto self-center "}>
                    Submit
                </FormButton>
            </FormCard>


        </form>

    )
}
