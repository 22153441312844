
import { initializeApp } from "firebase/app";
import { getMessaging,onMessage,getToken } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyBwL2c3qyOSb0XrASweE7WGUx3hdR6h_PE",
    authDomain: "healthboxes-85548.firebaseapp.com",
    projectId: "healthboxes-85548",
    storageBucket: "healthboxes-85548.firebasestorage.app",
    messagingSenderId: "560538251459",
    appId: "1:560538251459:web:60550f2859e92217b9f276",
    measurementId: "G-3SPWESR2HB"
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

