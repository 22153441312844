import React, {useEffect} from 'react'

function PageTitle({children, className}) {
    useEffect(() => {
    //     set the title of the page
        document.title = children + " - HealthBoxes"
    }, []);
    return (
        <h1 className={`${className} my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200`}>{children}</h1>
    )
}

export default PageTitle
