import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {useEffect, useState} from "react";
import Spinner from "../../../components/Loaders/Spinner/Spinner";
import DpCircle from "../../../components/avatar/dp/DpCircle";
import {getUri} from "../../../utils/utils";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";
import {Select} from "antd";

export default function AddUpdatePatient({className, ...props}) {
    let {id} = useParams();
    const [form, error, loading, setForm, actions] = useForm({
        user: {
            full_name: "",
            email: "",
            date_of_birth: new Date().toISOString().split("T")[0],
            cnic: "",
            martial_status: "SINGLE",
            gender: "MALE",
            address: "",
        },
        have_chronic_disease: false,
        ify_disease: "",
        contact: "",
        emergency_contact: "",
    });
    const navigation = useNavigate();
    const [loadingData, setLoadingData] = useState(false);

    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.PatientCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setForm(
                {
                    ...data,
                    user: {
                        ...data.user,
                        date_of_birth: moment(data.user.date_of_birth).format("YYYY-MM-DD")


                    }
                }
            )
            setLoadingData(false);
        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
            navigation("/app/patient")
        })

    }

    useEffect(() => {
        if (id) {
            getFormData()
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        const formattedData = {
            ...form,
            user: {
                ...form.user,
                dp: form.user.dp?.id,
            }
        };

        if (id) {
            actions.put(URLS.PatientCrud, () => formattedData)
                .then(res => {
                    toast.success("Patient Updated Successfully");
                    navigation("/app/patient");
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            actions.post(URLS.PatientCrud, () => formattedData)
                .then(res => {
                    toast.success("Patient Added Successfully");
                    navigation("/app/patient");
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    if (loadingData)
        return <PageLoading/>

    return (
        <form onSubmit={handleSubmit}
              className={`w-full flex flex-col h-full overflow-auto  pb-20 mt-2 xl:px-10 ${className}`}>
            <PageTitle>
                {
                    id ? "Update " : "Add"
                } Patient
            </PageTitle>


            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    Information
                </PageTitle>

                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4"}>
                    <div className={"flex justify-center items-center lg:col-span-2"}>
                        <DpCircle
                            src={getUri(form?.user?.dp?.file)}
                            onChange={(dp) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        dp: dp
                                    }
                                })
                            }}
                        />
                    </div>
                    <FormItem
                        label={"Full Name"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.user?.full_name}
                    >
                        <input
                            value={form?.user?.full_name}
                            placeholder={"John"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        full_name: e.target.value

                                    }
                                })
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Email"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.email}
                    >
                        <input
                            value={form?.user?.email}
                            placeholder={"example@gmail.com"}
                            min={0}
                            required={true}
                            type={"email"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        email: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>

                    <FormItem
                        label={"Gender"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.gender}
                    >
                        <Select
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            value={form?.user?.gender}
                            required={true}
                            onChange={(value) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        gender: value
                                    }
                                })
                            }}
                            options={[
                                {
                                    label:"Male",
                                    value:"MALE"
                                },
                                {
                                    label:"Female",
                                    value:"FEMALE"
                                },
                                {
                                    label:"Other",
                                    value:"OTHER"
                                },
                            ]}
                        >

                        </Select>
                    </FormItem>
                    <FormItem
                        label={"Date of Birth"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.date_of_birth}
                    >
                        <input
                            value={moment(form?.user?.date_of_birth).format("YYYY-MM-DD")}
                            required={true}
                            defaultValue={moment().format("YYYY-MM-DD")}
                            type={"date"}
                            onChange={(e) => {
                                const selectedDate = moment(e.target.value).format("YYYY-MM-DD");
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        date_of_birth: selectedDate
                                    }
                                });
                            }}
                        />
                    </FormItem>



                    <FormItem
                        label={"Marital Status"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.martial_status}
                    >
                        <Select
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            value={form?.user?.martial_status}
                            required={true}
                            onChange={(value) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        martial_status: value
                                    }
                                })
                            }}
                            options={
                                [
                                    {
                                        label: "Single",
                                        value: "SINGLE"
                                    },
                                    {
                                        label: "Married",
                                        value: "MARRIED"
                                    }
                                ]
                            }
                        >

                        </Select>
                    </FormItem>
                    <FormItem
                        label={"Chronic Disease"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.have_chronic_disease}
                    >
                        <Select
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer mt-1"}
                            variant={"borderless"}
                            size={"large"}
                            labelRender={
                                (item) => {
                                    return <span className={"text-gray-500 text-sm dark:text-white"}>{item.label}</span>
                                }
                            }
                            value={form?.have_chronic_disease ? "Yes" : "No"}
                            required={true}
                            onChange={(value) => {
                                setForm({...form, have_chronic_disease: value === "Yes"})
                            }}
                            options={[
                                {
                                    label:"Yes",
                                    value:"Yes"
                                },
                                {
                                    label:"No",
                                    value:"No"
                                }
                            ]}
                        >

                        </Select>
                    </FormItem>
                    <FormItem
                        label={"IFY Disease"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.ify_disease}
                    >
                        <input
                            value={form?.ify_disease}
                            placeholder={"if any"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, ify_disease: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Address"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.address}
                    >
                        <input
                            value={form?.user?.address}
                            placeholder={"address"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, user: {...form.user, address: e.target.value}})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Contact"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.contact}
                    >
                        <input
                            value={form?.contact}
                            placeholder={"contact"}
                            required={true}
                            type={"text"}
                            pattern={"^\+234\d{10}$"}
                            title={"Contact should be in +234xxxxxxxxxx format"}
                            onChange={(e) => {
                                setForm({...form, contact: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Emergency Contact"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.emergency_contact}
                    >
                        <input
                            value={form?.emergency_contact}
                            placeholder={"Emergency contact"}
                            required={true}
                            type={"text"}
                            pattern={"^\+234\d{10}$"}
                            title={"Contact should be in +234xxxxxxxxxx format"}
                            onChange={(e) => {
                                setForm({...form, emergency_contact: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Password"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.user?.password}
                    >
                        <input
                            value={form?.user?.password}
                            placeholder={"password"}

                            type={"password"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        password: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>
                </div>

                <FormButton
                    loading={loading}
                    type={"submit"} className={" mt-8 w-[500px] m-auto self-center "}>
                    Submit
                </FormButton>
            </FormCard>


        </form>

    )
}
