import Avatar from "../../../../components/Image/Avatar/Avatar";
import Badge from "../../../../components/Badges/Badge";
import {getRandomColor, getUri} from "../../../../utils/utils";
import moment from "moment";


function ProfileItem({title, value, className}) {


    return (
        <div className={`${className} flex flex-col pb-2 gap-2 border-b-[1px] dark:border-gray-400 `}>
            <p className={"font-semibold text-base opacity-60 dark:text-white text-gray-500"}>
                {title}
            </p>
            <p className={"text-sm dark:opacity-90 dark:text-white text-gray-800"}>
                {value}
            </p>
        </div>
    )

}

export default function PatientDetailCard({className, data, ...props}) {
    const diseases = []

    console.log(data?.user)


    return (
        <div className={` ${className} card p-4 `}>
            <div className={"w-full flex flex-col md:flex-row "}>
                <div className={"flex-[2] flex flex-col md:border-r-[1px] justify-center items-center  gap-4"}>
                    <Avatar
                        className={"w-24 h-24"}
                        src={getUri(data?.user?.dp?.file)}
                    />
                    <div className={"flex-col-center mt-2"}>
                        <p className={"font-bold dark:text-white text-xl "}>
                            {data?.user?.full_name}
                        </p>
                        <p className={"text-black font-thin dark:text-white opacity-70 dark:opacity-40  text-sm "}>
                            {data?.user?.email}
                        </p>
                    </div>
                    <div className={"flex-row-center flex-wrap gap-2 "}>
                        {
                            diseases.map((disease, index) => (
                                <Badge key={index}
                                       color={getRandomColor()}
                                       className={"px-3 whitespace-nowrap"}>
                                    {disease}
                                </Badge>
                            ))
                        }

                    </div>


                </div>
                <div className={"flex-[3]"}>
                    <div className={"w-full p-6 gap-2 grid grid-cols-1 md:grid-cols-2"}>
                        <ProfileItem
                            title={"PID"}
                            value={
                                data?.pid
                            }
                        />
                        <ProfileItem
                            title={"Gender"}
                            value={
                                data?.user?.gender
                            }
                        />
                        <ProfileItem
                            title={"Date of Birth"}
                            value={
                                moment(data?.user?.date_of_birth).format("LL")
                            }
                        />
                        <ProfileItem
                            title={"Phone"}
                            value={
                                data?.contact
                            }
                        />
                        <ProfileItem
                            title={"Marital Status"}
                            value={
                                data?.user?.martial_status === "SINGLE" ? "Single" : "Married"
                            }
                        />

                        <ProfileItem
                            className={""}
                            title={"Address"}
                            value={
                                data?.user?.address
                            }
                        />
                    </div>

                </div>
            </div>
        </div>

    )
}
