import {combineReducers} from "redux";
import ThemeReducer from "./Reducers/ThemeReducer";
import DrawerReducer from "./Reducers/DrawerReducer";
import AuthReducer from "./Reducers/AuthReducer";
import NotificationReducer, {NotificationTokenReducer} from "./Reducers/notification-reducer/NotificationReducer";


const rootReducer = combineReducers({
    ThemeReducer,
    DrawerReducer,
    AuthReducer,
    NotificationReducer,
    NotificationTokenReducer
})


export default rootReducer;
