import {useAlterBox} from "../../../../Modals/AlertModal/Context";
import {useState} from "react";
import DummyData from "../../../dashboard/tables/ClientTable/varaibales/DummyData";
import PageTitle from "../../../../components/utils/PageTitle";
import {Link} from "react-router-dom";
import TableRow from "../../../../components/Table/TableRow";
import {AiFillEye, AiOutlineAppstoreAdd} from "react-icons/ai";
import TableContainer from "../../../../components/Table/TableContainer";
import Table from "../../../../components/Table/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableCell from "../../../../components/Table/TableCell";
import TableBody from "../../../../components/Table/TableBody";
import TableLoadingRow from "../../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../../components/Table/TableEmpty";
import Avatar from "../../../../components/Image/Avatar/Avatar";
import Badge from "../../../../components/Badges/Badge";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../../../components/Table/TableFooter";
import Paginator from "../../../../components/Table/Paginator";
import dummyPatientList from "../../../../utils/dummy-data/dummy-patient-list";
import download, {getUri} from "../../../../utils/utils";
import {FaFileDownload} from "react-icons/fa";
import useListApi from "../../../../Hooks/useListApi";
import URLS from "../../../../API/Axios/URLS";
import moment from "moment";
import {useSession} from "../../../../redux/Reducers/AuthReducer";
import SearchInput from "../../../../components/fields/search-input/search-input";

export default function PatientTable({className,onRowSelect,isModal, ...props}) {


    let [data, filter, isLoading, patientActions] = useListApi(URLS.PatientList, {
        search: "",
    });
    const {authActions} = useSession();


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            {!isModal &&<div className={"w-full flex mb-10 justify-end items-center"}>
                {authActions.is_admin() && <Link title={"Add patient"}
                                                 className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                                                 to={"/app/patient/add"}>
                    <AiOutlineAppstoreAdd className={"text-base"}/>
                </Link>}
            </div>}
            <div
                className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
            >
                <SearchInput
                    hideRightIcon={true}
                    onChange={(e) => {
                        patientActions.updateFilter({
                            search: e.target.value
                        })
                    }}
                    outerClassName={"border-[1px]  rounded-full"}
                    placeholder={"Search patient"}
                />


            </div>
            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Name & PID</TableCell>
                            <TableCell>Register Date</TableCell>
                            <TableCell>Contact </TableCell>
                            <TableCell>Attachments</TableCell>
                            {!isModal &&<TableCell>Actions</TableCell>}
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((row, i) => {
                            let user = row?.user;
                            return <TableRow
                                onClick={() => {
                                    if (onRowSelect)
                                        onRowSelect(row)
                                }}
                                key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(user?.dp?.file)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{user?.full_name}</p>
                                            <p className="text-sm">{row?.pid}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{
                                        moment(new Date(user?.date_joined)).format('ll')
                                    }</span>
                                </TableCell>

                                <TableCell>
                                    <span className="text-sm">{

                                        row?.contact
                                    }</span>
                                </TableCell>
                                <TableCell>

                                    <FaFileDownload
                                        onClick={() => {

                                            download(getUri(user?.attachment))
                                        }}
                                        className={"cursor-pointer"}/>
                                </TableCell>
                                {!isModal &&<TableCell>
                                    <div className={"flex gap-2"}>
                                        <Link to={"/app/patient/" + row.id}>
                                            <AiFillEye title={"view"} className={"cursor-pointer"}/>
                                        </Link>

                                        {authActions.is_admin() && (
                                            <> <Link to={"/app/patient/update/" + row.id}>
                                                <MdEditNote title={"edit"}

                                                            className={"cursor-pointer"}/>
                                            </Link>

                                            </>
                                        )}
                                    </div>
                                </TableCell>}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            patientActions.loadNextPage()
                        }}
                        onPrev={(e) => patientActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            patientActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
