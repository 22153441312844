import { useEffect, useState } from "react";
import React from "react";
import {FiEye, FiEyeOff} from "react-icons/fi";

export default function FormItem({
                                     label,
                                     id,
                                     type,
                                     message,
                                     variant,
                                     className,
                                     LeftIcon,
                                     RightIcon,
                                     children,
                                     ...props
                                 }) {
    const [styleClass, setStyleClass] = useState({
        border: "border-gray-200 dark:!border-white/10",
        text: "text-gray-500 dark:text-white",
    });

    const [showPassword, setShowPassword] = useState(false);

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    // Update the style classes based on message and type
    function updateStyleClass() {
        if (!message?.length) {
            setStyleClass({
                border: "border-gray-200 dark:!border-white/10",
                text: "text-gray-500 dark:text-white",
            });
            return;
        }
        switch (type) {
            case "success":
                setStyleClass({
                    border: "border-green-500 dark:!border-green-400",
                    text: "text-green-500 dark:text-green-400",
                });
                break;
            case "error":
                setStyleClass({
                    border: "border-red-500 dark:!border-red-400",
                    text: "text-red-500 dark:text-red-400",
                });
                break;
            case "hint":
                setStyleClass({
                    border: "border-yellow-500 dark:!border-yellow-300",
                    text: "text-yellow-500 dark:text-yellow-300",
                });
                break;
            default:
                setStyleClass({
                    border: "border-gray-200 dark:!border-white/10",
                    text: "text-gray-500 dark:text-white",
                });
        }
    }

    useEffect(() => {
        updateStyleClass();
    }, [type, message]);

    return (
        <div className={`${styleClass.text} ${className}`}>
            {label && (
                <label
                    htmlFor={id}
                    className={`text-sm ${
                        variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
                    }`}
                >
                    {label}
                </label>
            )}
            <div
                className={`border mt-2 rounded-xl flex flex-row justify-center items-center gap-3 
                ${styleClass.border}`}
            >
                {LeftIcon && <LeftIcon className="w-5 h-5" />}
                {children
                    ? React.cloneElement(children, {
                        className: `${children.props.className} flex-1 outline-none rounded-xl p-2 lg:py-3 ${styleClass.text} bg-transparent text-sm`,
                        id: id,
                        type: label === "Password" && showPassword ? "text" : children.props.type,
                        ...props,
                    })
                    : null}
                {(label === "Password" || label === "Old Password" || label === "New Password" ) && (
                    <button
                        type="button"
                        className="toggle-password text-gray-500 dark:text-white pr-2"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ?
                            <FiEye />:<FiEyeOff />


                        }
                    </button>
                )}
                {RightIcon && <RightIcon className="w-5 h-5" />}
            </div>
            {message?.length > 0 && <p className="text-xs mt-1 ml-2">{message}</p>}
        </div>
    );
}
