import {useDispatch, useSelector} from "react-redux";
import {ReactNode, useEffect, useState} from "react";
import { notification } from 'antd';
import {BsInfo} from "react-icons/bs";
import {getToken, onMessage} from "firebase/messaging"
import {toast} from "react-toastify";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {messaging} from "../../../firebase-config";




const initialState = {}

export const ACTIONS = {
    FETCHALL: 'FETCHALL',
    SET_DATA: 'SET_DATA',
    UPDATE_DATA: 'UPDATE_DATA',
    CLEAR_DATA: 'CLEAR_DATA',

}
export const TOKENACTIONS = {

    SET_TOKEN: 'SET_TOKEN',
    CLEAR_TOKEN: 'CLEAR_TOKEN',

}


const NotificationDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CLEAR_DATA:
            return initialState;
        case ACTIONS.SET_DATA:
            return {
                ...action.payload
            }
        case ACTIONS.UPDATE_DATA:
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }
}
export const NotificationTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOKENACTIONS.CLEAR_TOKEN:
            return "";
        case TOKENACTIONS.SET_TOKEN:
            return action.payload


        default:
            return state;
    }
}

export function useNotifications() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.NotificationTokenReducer);
    const [loading] = useState(false)

    // const clearData = () => {
    //     dispatch({type: ACTIONS.CLEAR_DATA})
    // }
    const setToken = (data) => {
        dispatch({type: TOKENACTIONS.SET_TOKEN, payload: data})
    }

    const updateWebToken = (data) => {
        if (!data) {
           dispatch({type: TOKENACTIONS.CLEAR_TOKEN})
            return
        }
        dispatch({type: TOKENACTIONS.SET_TOKEN, payload: ""+data})

        // new NotificationEndpoints().updateWebToken(data).then(() => {})
        axiosInstance.post( URLS.updateWebToken,{
            token:data
            }).then({})

    }


    const requestForToken = () => {
        return getToken(messaging, {vapidKey: "BGdCY07yuetTej2j-CuFgIP9xH0QgI3fOZ026pfuvpGvM3N8iyKJh8AsNHBirM3t2nW7nPl-ZeBI0sOBa5p3zdU"})
            .then((currentToken) => {
                if (currentToken) {
                    // console.log('current token for client: ', currentToken);
                    updateWebToken(currentToken)
                    setToken(currentToken)
                    console.log("Token set", currentToken)
                } else {
                    toast.error("No registration token available. Request permission to generate one.")
                    console.log('No registration token available. Request permission to generate one.');
                    updateWebToken("")
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    };
    // const fetchNotifications = () => {
    //     ListActions.load(
    //         {
    //             page: 1,
    //             page_size: 8
    //         }
    //     )
    // }
    // const hasMore = () => {
    //     return ListActions.hasMore()
    // }
    // const loadMore = () => {
    //     ListActions.loadMore()
    // }



    const actions = {
        // clearData: clearData,
        requestForToken: requestForToken,
        // fetchNotifications: fetchNotifications,
        // hasMore: hasMore,
        // loadMore: loadMore,

    }

    return { token, actions, loading}
}


export function NotificationWrapper({children}) {


    useEffect(() => {
        console.log("listening for messages")
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log(payload)
            let icon = <div className="w-7 h-7 bg-blue-100 flex items-center rounded-full">
                <BsInfo className="h-4 w-4 text-gray-500 mx-auto my-auto"/>
            </div>
            if (payload?.notification?.image) {
                icon = <img className={"w-8 h-8"} src={payload?.notification?.image} alt=""/>
            }
            console.log('Message received. ', payload);
            const noti = <div className="notification flex gap-2">
                <div className="notification-icon">{icon}</div>
                <div className="notification-content">
                    <div className="font-semibold  ">{payload?.notification?.title}</div>
                    <div className="notification-body">{payload?.notification?.body}</div>
                </div>
            </div>
            toast(noti)
            // notification.open({
            //     message: payload?.notification?.title,
            //     description: payload?.notification?.body,
            //     icon: icon,
            //     onClick: () => {
            //         console.log('Notification Clicked');
            //     },
            //     duration: 0
            // });
        });

        // Clean up function
        return () => {
            unsubscribe();
        };
    }, []);

    const {actions} = useNotifications();
    useEffect(() => {
        actions.requestForToken().then(() => {
            console.log("Token requested")
        })
    }, [])

    return <>
        {children}
    </>
}

export default NotificationDataReducer;
