import AppointmentModal from "../../../Modals/appointment-slection-modal/appointment-selction-modal";
import {useEffect, useState} from "react";
import FormItem from "../../../components/Form/FormItem/FormItem";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";


export default function PaymentCard({className, ...props}) {
    const [open, setOpen] = useState(false);
    const [selectedAppointment, setSelected] = useState(null);
    const [form, error, loading, setFormData, actions] = useForm({
        paymentType: "CASH",
    })


    async function onSubmit() {
        actions.post(URLS.PaymentAdd).then(r => {
           if(r) {
               toast.success("Payment added successfully")
               props.setRefresh(true)
           }else {
               console.log(error)
            toast.error("Failed to add payment")
           }
            })
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.error || error?.message || error?.detail)
        }
    }, [error]);

        return (
            <div className={"flex flex-1 flex-col rounded-xl   shadow w-full bg-gradient-to-t from-orange-500 via-orange-400 to-orange-500 dark:bg-brand-700 p-4"}>
                <AppointmentModal
                    isOpen={open}
                    setIsOpen={setOpen}
                    onSelect={(data)=>{
                        setSelected(data);
                        setFormData({
                            ...form,
                            appointment: data.id
                        })
                        setOpen(false);
                    }}

                />

                <h1 className={"text-brand-700 font-semibold text-xl"}>
                    Add Payment
                </h1>

                <div className={" grid grid-cols-4 gap-4 mt-4"}>
                    <div className={"flex flex-col gap-2"}>
                        <label htmlFor="appointment" className={"text-white"}>Appointment</label>
                        <input
                            type="text"
                            id={"appointment"}
                            value={selectedAppointment?.appointmentID}
                            onClick={() => setOpen(true)}
                            className={"p-2 rounded-md bg-white "}
                        />
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <label htmlFor="amount" className={"text-white"}>Amount (in NGN)</label>
                        <input
                            type="text"
                            id={"amount"}
                            value={form.amount}
                            onChange={(e) => {
                                setFormData({
                                    ...form,
                                    amount: e.target.value
                                })
                            }}
                            className={"p-2 rounded-md bg-white"}
                        />
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <label htmlFor="paymentMethod" className={"text-white"}>Payment Method</label>
                        <select
                            id={"paymentMethod"}
                            value={form.paymentType}
                            onChange={(e) => {
                                setFormData({
                                    ...form,
                                    paymentType: e.target.value
                                })
                            }}
                            className={"p-2 rounded-md bg-white"}
                        >
                            <option value={"CASH"}>Cash</option>
                            <option value={"CARD"}>Card</option>
                            <option value={"CHEQUE"}>Cheque</option>
                        </select>
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <label htmlFor="date" className={"text-white"}>Date Paid</label>
                        <input
                            type="datetime-local"
                            value={form.datePaid}
                            onChange={(e) => {
                                setFormData({
                                    ...form,
                                    datePaid: e.target.value
                                })
                            }}
                            id={"date"}
                            className={"p-2 rounded-md bg-white"}
                        />
                    </div>
                    <div className={"flex flex-col gap-2 col-span-4"}>
                        <label htmlFor="note" className={"text-white"}>Note</label>
                        <textarea
                            id={"note"}
                            value={form.notes}
                            onChange={(e) => {
                                setFormData({
                                    ...form,
                                    notes: e.target.value
                                })
                            }}
                            className={"p-2 rounded-md bg-white"}
                        />
                    </div>
                    <div className={"flex flex-col gap-2 col-span-4 "}>
                        <button
                            onClick={onSubmit}
                            className={"bg-orange-500 border border-white text-white active:ring-2 active:ring-brand-400 p-2 rounded-md shadow-inner font-semibold shadow-brand-400"}
                        >
                            Add Payment
                        </button>
                    </div>
                </div>




            </div>

        )
    }


