import AuthLayout from "../../layout/auth/auth";
import {Route, Routes} from "react-router-dom";
import Login from "./Login/Login";
import ForgetPassword from "./ForgetPassoword/ForgetPassword";
import NewPassword from "./ForgetPassoword/NewPassword";
import OTP from "./ForgetPassoword/OTP";
import LoginOTP from "./Login/login-otp";
import PatientRegister from "./patient-register/patient-register";

export default function AuthEntry({className, ...props}) {
    window.title = "HealthBoxes"

    return (
        <AuthLayout>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login-otp" element={<LoginOTP/>}/>
                <Route path="/patient-register" element={<PatientRegister/>}/>
                <Route path="/forget-password" element={<ForgetPassword/>}/>
                <Route path="/otp" element={<OTP/>}/>
                <Route path="/new-password" element={<NewPassword/>}/>

            </Routes>


        </AuthLayout>

    )
}
