import {Navigate, Route, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import URLS from "../../API/Axios/URLS";
import useForm from "../../Hooks/useForm";
import {useSession} from "../../redux/Reducers/AuthReducer";
import Avatar from "../../components/Image/Avatar/Avatar";
import {PreviousMessages} from "../dashboardDoctor/Messaging";
import PageTitle from "../../components/utils/PageTitle";


export default function AdminMessages({className,...props}) {
    const [form, error, loading, setFormData, actions]  = useForm()
    const {authActions} = useSession()

    const [threads, setThreads] = React.useState()
    const [selectedThread, setSelectedThread] = React.useState()
    const [messages, setMessages] = React.useState()
    useEffect(() => {
        if(!threads){
            actions.get(URLS.GetMyThreads).then((data) => {
                setThreads(data?.data)

            }).catch((error) => {
                console.log(error)
            })

        }
    }, []);

     return (
         <div className={`flex  flex-col lg:flex-row  py-[4rem]  lg:pt-[4rem] px-5 gap-4 ${className}`}>

             <div className={"shadow-lg bg-white h-fit lg:min-h-[80vh] dark:bg-darkBgSecondary dark:text-white dark:text-opacity-60 rounded-xl p-4 w-full lg:w-1/3 "}>
                 <PageTitle>
                     Conversations
                 </PageTitle>

                    <div className={"flex flex-col gap-2"}>
                        {
                            threads?.map((threadConvo, index) => {
                                return (
                                    <div className={`flex w-full flex-1 flex-col gap-2
                                     ${selectedThread ? selectedThread?.id !==threadConvo?.id && "hidden lg:flex":"flex"}
                                    `}>
                                        <div key={index} className={`flex gap-2 rounded-lg  p-2 cursor-pointer
                                    ${selectedThread?.id !==threadConvo?.id  && "hover:bg-gray-100 dark:hover:bg-darkBgTertiary "}
                                    ${selectedThread?.id === threadConvo?.id && "bg-orange-500 dark:bg-orange-900 border-y-0 text-white flex "}
                                    `} onClick={() => {
                                        if (selectedThread?.id !== threadConvo?.id){
                                            setSelectedThread(threadConvo)
                                        }else{
                                            setSelectedThread(null)
                                        }
                                        }}>
                                            <div>
                                                <Avatar />
                                            </div>
                                            <div className={"flex flex-1 justify-between"}>
                                                <div>
                                                    <h1 className={`${selectedThread?.id === threadConvo?.id &&"text-white"} text-lg font-semibold`}>{threadConvo?.name}</h1>
                                                    <p className={`text-sm  ${selectedThread?.id === threadConvo?.id ?"text-gray-300":"text-gray-500"}`}>{threadConvo?.last_message}</p>

                                                </div>

                                            </div>
                                        </div>
                                        {index !== threads?.length -1 &&
                                            <div>
                                            <div className={`w-full h-[1px] bg-gray-300 dark:bg-darkBgTertiary`}></div>
                                        </div>}
                                    </div>
                                    )
                            })
                        }
                    </div>


             </div>

             {selectedThread && <div className={"w-full rounded-xl lg:w-2/3 bg-white p-4 "}>
                 <PreviousMessages
                     className={"!max-h-[76vh]"}
                     thread_id={selectedThread?.id} title={selectedThread?.name}/>
             </div>}
         </div>

     )
 }
