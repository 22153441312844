import React, {useEffect} from "react";
import useForm from "../../Hooks/useForm";
import URLS from "../../API/Axios/URLS";
import {useSession} from "../../redux/Reducers/AuthReducer";
import Avatar from "../../components/Image/Avatar/Avatar";


const PreviousMessages = ({thread_id,title,refresh,className}) => {
    const [form, error, loading, setFormData, actions]  = useForm()
    const {authActions} = useSession()
    const [thread, setThread] = React.useState()
    const [messages, setMessages] = React.useState()

    function fetchMessages(){

        actions.get(URLS.GetMessages + thread).then((data) => {
            setMessages(data?.data)
        }).catch((error) => {
            console.log(error)
        })

    }
    useEffect(()=>{
        if(thread_id){
            if(thread_id !== thread){
                setThread(thread_id)
            }
        }


    },[thread_id])
    useEffect(() => {
        if(!thread){
            if(!thread_id){
                setMessages([])

                actions.get(URLS.GetMyThreads).then((data) => {
                    if(authActions.is_doctor()){
                        setThread(data?.data[0]?.id)
                    }
                }).catch((error) => {
                    console.log(error)
                })

            }
            else{
                setThread(thread_id)
            }

        }
        else {
            fetchMessages()



        }

    }, [thread]);


    useEffect(() => {
        if(refresh?.refresh){
            fetchMessages()
            refresh?.setRefresh(false)
        }
    }, [refresh?.refresh]);
    return (
        <div className={`${className} w-full overflow-scroll`}>
            {title &&<div>
                <h1 className="my-6 text-lg font-semibold text-gray-700 dark:text-gray-200 ">{title}</h1>
            </div>}
            <div className={"bg-white rounded-3xl w-full p-4"}>
                {
                    messages?.map((message, index) => {
                        return (
                            <div key={index} className={" border-b border-gray-300 pb-4 mb-2 w-full"}>
                                <div className={"flex gap-2 rounded border-l-2  border-l-orange-500 p-2"}>
                                    <div>
                                        <Avatar />
                                    </div>
                                    <div className={"flex flex-1 justify-between"}>
                                        <div>
                                            <h1 className={"text-lg font-semibold"}>{message?.sender?.full_name}</h1>
                                            <p className={"text-sm text-gray-500"}>{message?.message}</p>
                                        </div>
                                        <div>
                                            {/* message.created_at is 2024-12-21T12:23:08.849763+01:00 convert to April 24, 2024 @11:30 PM localtime*/}
                                            <p className={"text-sm text-gray-500"}> {new Date(message?.created_at).toLocaleString('en-US', {
                                                month: 'short',
                                                day: 'numeric',
                                                year: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',

                                                hour12: true
                                            })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}


const Messaging = ({title}) => {

    const [form, error, loading, setFormData, actions]  = useForm()
    const [thread, setThread] = React.useState()
    const [refresh, setRefresh] = React.useState(false)

    function sendMessage() {
        if(form?.message){

            actions.post(URLS.SendMessage).then((data) => {
               if (data?.data?.success){

                   setThread(data?.success)
                   setRefresh(true)
                   setFormData({
                       message: ""})

               }
            })



        }
    }


    return (
        <div className={"w-full "}>
            <div>
                <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">{title}</h1>
            </div>
            <div className={"bg-white rounded-3xl w-full p-4"}>

                <div className={" border-b border-gray-300 pb-4 mb-2 w-full"}>
                            <textarea
                                placeholder={"Write a message to admin"}
                                value={form?.message}
                                onChange={(e) => {
                                    setFormData({
                                        ...form,
                                        message: e.target.value
                                    })
                                }}
                                className={"outline-none w-full border border-gray-500 rounded-xl p-2"}
                            >

                        </textarea>
                    <div className={"w-full flex flex-row-reverse"}>
                        <button
                            onClick={sendMessage}
                            className={"text-sm bg-orange-500 rounded-xl p-2 text-white cursor-pointer"}>
                            Send To Admins
                        </button>
                    </div>

                </div>

                <PreviousMessages
                    title={"Previous Messages"}
                    className={"!max-h-[55vh]"}
                    refresh={{
                        refresh,
                        setRefresh
                    }}
                    thread_id={thread}

                />
            </div>
        </div>

    )

}

export default Messaging;
export {PreviousMessages};
